import {DerivationService} from '@shared/interfaces/derivation.interface';

export const SERVICE: { ih: DerivationService[]; noIh: DerivationService[] } = {
  ih: [
    {
      id: '1',
      specialty: 'Urgencia Hospital Clinico Marcoleta',
      service: '',
    },
    {
      id: '2',
      specialty: 'Urgencia Clinica San Carlos',
      service: '',
    },
    {
      id: '3',
      specialty: 'Otra Especialidad',
      service: '',
    },
    {
      id: '7',
      specialty: 'Terapia Ocupacional',
      service: '',
    },
    {
      id: '4841B364-2949-491A-BDED-A98A00F1DCD9',
      specialty: 'Fonoaudiología',
      service: '',
    },
    {
      id: '9',
      specialty: 'Psicopedagogia',
      service: '',
    },
    {
      id: '10',
      specialty: 'Seguimiento Orientacion Quirurgica',
      service: '',
    },
    {
      id: '12',
      specialty: 'Urgencia Externa',
      service: '',
    },
    {
      id: '13',
      specialty: 'Pre Dialisis',
      service: '',
    },
    {
      id: 'PEMP',
      specialty: 'Psiquiatría de Enlace y Medicina Psicosomática Ambulatoria',
      service: '(PEMP Ambulatorio)',
    },
    {
      id: 'insuficiencia',
      specialty: 'Programa de insuficiencia Cardiaca',
      service: 'UC',
      fonasaIds: ["0101301"],
      imedIds: ["0101817"]
    },
    {
      id: '946024DA-B3A7-4B58-B985-AB3B00BF5413',
      specialty: 'Kinesiología',
      service: '',
    },
    {
      id: '3BC7BE6D-6518-4EC7-A46D-AB8D014CFD7B',
      specialty: 'MEDICINA FAMILIAR ADULTOS',
      service: 'Cuidados Paliativos TLM',
    },
    {
      id: '4BCBB722-73C1-4D5D-A8D8-AB8500BE7B55',
      specialty: 'NEUROLOGIA ADULTOS',
      service: 'General TLM',
    },
    {
      id: '1DA332CB-907A-4DEC-85A3-ABC10048F876',
      specialty: 'TRAUMATOLOGIA ADULTO',
      service: 'Codo TLM',
    },
    {
      id: 'F2B48D5F-CD54-4BFC-BDC4-A98A00F1DE00',
      specialty: 'GASTROENTEROLOGIA ADULTOS',
      service: 'Hepatologia',
    },
    {
      id: '924FA341-4E7D-40E0-B2E9-A98A00F1F282',
      specialty: 'NEFROLOGIA PEDIATRICA',
      service: 'General',
    },
    {
      id: '6D1636F0-9FCC-46E0-8612-A99E01459E06',
      specialty: 'NUTRICION MEDICA PEDIATRICA',
      service: 'Trastornos de la Alimentacion',
    },
    {
      id: '3FC84598-646B-4F84-840C-AC5301090A13',
      specialty: 'NEUROLOGIA ADULTOS',
      service: 'Enfermedades Neuromusculares',
    },
    {
      id: 'DDC218BE-33FF-4F33-BA84-AC02017EB835',
      specialty: 'MEDICINA DEPORTIVA',
      service: 'General TLM',
    },
    {
      id: '4013BC29-E6C8-4208-92EC-A98A00F1F461',
      specialty: 'NEUROCIRUGIA',
      service: 'General',
    },
    {
      id: '45778B2D-DB7C-497E-BB69-AF5B00CCBDA0',
      specialty: 'CARDIOLOGIA ADULTOS',
      service: 'General',
    },
    {
      id: 'BBA2E8AB-5FFB-4329-B4AB-A99E0145AA80',
      specialty: 'PEDIATRIA',
      service: 'Seguimiento a Prematuro',
    },
    {
      id: '3F57A3C1-239C-4BAA-B83C-A99E0145A664',
      specialty: 'ONCOLOGIA PEDIATRICA',
      service: 'General',
    },
    {
      id: 'D6CC8A92-DB36-4A24-89D3-AB8500B80E4A',
      specialty: 'ONCOLOGIA PEDIATRICA',
      service: 'General TLM',
    },
    {
      id: 'FAB36173-EB33-4647-8A19-A98A00F1CC6F',
      specialty: 'ANESTESIOLOGIA',
      service: 'Manejo Dolor Cronico',
    },
    {
      id: 'E7E8C4E9-82D7-48A7-86B0-AB8500BAF178',
      specialty: 'REUMATOLOGIA PEDIATRICA',
      service: 'General TLM',
    },
    {
      id: 'E8AB8B12-41EC-464E-8E32-A98A00F1D894',
      specialty: 'DERMATOLOGIA',
      service: 'Dermatologia Pediatrica',
    },
    {
      id: '41424E8E-CD3F-4034-9870-A98A00F22833',
      specialty: 'TRAUMATOLOGIA PEDIATRICA',
      service: 'General',
    },
    {
      id: '9AD5C7E2-2521-4EBE-9325-A98A00F221AE',
      specialty: 'TRAUMATOLOGIA ADULTO',
      service: 'General',
    },
    {
      id: '06F17B36-06A6-46C8-83E8-A98A00F22798',
      specialty: 'TRAUMATOLOGIA ADULTO',
      service: 'Columna',
    },
    {
      id: 'FB0AD74E-9A48-4F61-B82C-A9A60142C34F',
      specialty: 'TRAUMATOLOGIA ADULTO',
      service: 'Traumatologia Deportiva',
    },
    {
      id: '987DF81C-029A-4546-A806-A9A60142B488',
      specialty: 'DIABETOLOGIA PEDIATRICA',
      service: 'General',
    },
    {
      id: 'D4ABAE3C-C5CF-485E-9E3B-A98A00F2224A',
      specialty: 'TRAUMATOLOGIA ADULTO',
      service: 'Caderas',
    },
    {
      id: '16496AD1-5237-4AC2-A5FD-A98A00F2095C',
      specialty: 'ADOLESCENCIA',
      service: 'General',
    },
    {
      id: 'AA4977FF-3131-4903-8827-AB860105677F',
      specialty: 'ENDOCRINOLOGIA ADULTOS',
      service: 'General TLM',
    },
    {
      id: '552BFE10-98CF-4061-BC28-AB8500B98DCB',
      specialty: 'INFECTOLOGIA ADULTOS',
      service: 'General TLM',
    },
    {
      id: 'D26E637B-C8D2-421D-8915-B08B00EC4163',
      specialty: 'MEDICINA INTERNA',
      service: 'General TLM',
      imedIds: ["0101816"]
    },
    {
      id: '30292893-DB57-485B-9972-A9A60142B1D8',
      specialty: 'CIRUGIA ONCOLOGICA',
      service: 'Cancer de Piel',
    },
    {
      id: 'B15073C3-97F3-4508-861B-AB8500C84899',
      specialty: 'RADIOTERAPIA',
      service: 'General TLM',
    },
    {
      id: '1704B22A-E87C-49C7-95EA-A98A00F21BFD',
      specialty: 'REUMATOLOGIA ADULTOS',
      service: 'General',
    },
    {
      id: '2D18A4BE-A14E-4B52-82B3-ABC100495754',
      specialty: 'TRAUMATOLOGIA ADULTO',
      service: 'Columna TLM',
    },
    {
      id: '22313EF8-AE7A-4F8A-9CA9-AB8500B011DC',
      specialty: 'CIRUGIA PEDIATRICA',
      service: 'General TLM',
    },
    {
      id: '9444F65E-B7C6-4439-9795-AB8500BD26FC',
      specialty: 'NEFROLOGIA PEDIATRICA',
      service: 'General TLM',
    },
    {
      id: 'B3D8DB68-6AF1-4ECC-B6D0-A9A60142B16B',
      specialty: 'CIRUGIA ONCOLOGICA',
      service: 'Cabeza y Cuello',
    },
    {
      id: '5A091E76-EEE1-49B7-8E75-A98A01533BFA',
      specialty: 'PSIQUIATRIA PEDIATRICA Y ADOLESCENTE',
      service: 'General',
    },
    {
      id: 'E1B2DE90-4C94-4DB6-9AEA-A9A60142BECD',
      specialty: 'PSIQUIATRIA ADULTOS',
      service: 'Adicciones',
    },
    {
      id: 'FEAF2142-5748-438F-B70B-AC47014CABFF',
      specialty: 'NUTRICION MEDICA PEDIATRICA',
      service: 'Trastornos de la Alimentacion TLM',
    },
    {
      id: 'D331295F-8BE0-4F37-AC91-A98A00F21658',
      specialty: 'PSIQUIATRIA ADULTOS',
      service: 'General',
    },
    {
      id: 'D9F27094-1A15-470E-8721-AB8500B2C1F6',
      specialty: 'DIABETOLOGIA PEDIATRICA',
      service: 'General TLM',
    },
    {
      id: 'D786C6CA-84E8-4F51-A9CA-AB8500B26A1E',
      specialty: 'DIABETOLOGIA ADULTOS',
      service: 'General TLM',
    },
    {
      id: 'BA3CEBA7-D578-46CA-88B6-A98A00F1DD3B',
      specialty: 'GASTROENTEROLOGIA ADULTOS',
      service: 'General',
    },
    {
      id: '65C8B928-8E8F-4316-A3D3-A98A00F1F72E',
      specialty: 'NEUROLOGIA ADULTOS',
      service: 'Trastorno del Sueño',
    },
    {
      id: '874E8E2D-51EB-424E-891A-A98A00F1F7AD',
      specialty: 'NEUROLOGIA PEDIATRICA',
      service: 'General',
    },
    {
      id: 'E1A7F9E9-8A0E-490F-B002-AC6901401509',
      specialty: 'TRAUMATOLOGIA ADULTO',
      service: 'Tumores Musculoesqueleticos',
    },
    {
      id: 'E73E62B4-29EB-465C-8AE6-A98A00F1E6CF',
      specialty: 'INFECTOLOGIA ADULTOS',
      service: 'General',
    },
    {
      id: '5D6066C1-5919-4B25-AAC0-AB8500C74DEF',
      specialty: 'PSIQUIATRIA PEDIATRICA Y ADOLESCENTE',
      service: 'General TLM',
    },
    {
      id: '704F3E0D-4B9A-4CA1-BB3A-A98A00F1D489',
      specialty: 'CIRUGIA MAXILO FACIAL',
      service: 'General',
    },
    {
      id: 'DE4149F2-B4B0-4372-91FF-A98A00F228C9',
      specialty: 'UROLOGIA ADULTOS',
      service: 'General',
    },
    {
      id: '75C9A538-D50A-49F0-859B-A98A00F225CD',
      specialty: 'TRAUMATOLOGIA ADULTO',
      service: 'Rodillas',
    },
    {
      id: '910391CB-3DBC-4B42-825C-A98A00F1D5F6',
      specialty: 'CIRUGIA PLASTICA Y REPARADORA',
      service: 'General',
    },
    {
      id: '61400693-70C0-4D19-9D79-AB8500BA783D',
      specialty: 'INMUNOLOGIA Y ALERGIA PEDIATRICA',
      service: 'General TLM',
    },
    {
      id: '1E2B51BE-BE6F-4F0E-A9F5-A9AC00F7FC64',
      specialty: 'NEUROLOGIA PEDIATRICA',
      service: 'Neurorrehabilitacion',
    },
    {
      id: '8F57CFC1-B202-4AD6-BE7A-A98A00F1D2AB',
      specialty: 'CARDIOLOGIA PEDIATRICA',
      service: 'General',
    },
    {
      id: '5E7802A5-EB03-4290-AB1E-AB8500CA7F93',
      specialty: 'UROLOGIA ADULTOS',
      service: 'General TLM',
    },
    {
      id: '26D99FE7-EDA2-421A-AE4C-A98A015338B8',
      specialty: 'PARASITOLOGIA',
      service: 'General',
    },
    {
      id: 'EF3A9DD0-7082-4D1E-BB67-AB8500AC494F',
      specialty: 'ANESTESIOLOGIA',
      service: 'Manejo Dolor Cronico TLM',
    },
    {
      id: '1C47A422-5E81-4718-8307-A98A00F1E7DA',
      specialty: 'INMUNOLOGIA Y ALERGIA ADULTOS',
      service: 'General',
    },
    {
      id: '07A90886-42EF-4A09-8D9B-A98A00F1D667',
      specialty: 'CIRUGIA TORAX',
      service: 'General',
    },
    {
      id: 'E660D808-77A3-412E-84B0-A98A00F1E765',
      specialty: 'INFECTOLOGIA PEDIATRICA',
      service: 'General',
    },
    {
      id: '1A6250ED-A5D3-4EC2-AFB6-AB9D00E0D3DA',
      specialty: 'MANEJO DE DOLOR NO ONCOLOGICO',
      service: 'Manejo de Dolor TLM',
    },
    {
      id: '1C955310-62B6-4B95-9432-AB8500BF08ED',
      specialty: 'NEUROLOGIA PEDIATRICA',
      service: 'General TLM',
    },
    {
      id: 'F49E76C2-F7D3-4FB3-B149-AB8500C234D1',
      specialty: 'NUTRICION MEDICA ADULTOS',
      service: 'General TLM',
    },
    {
      id: '9DA5C64D-4C74-4913-9DAA-A98A00F1CE13',
      specialty: 'BRONCOPULMONAR ADULTOS',
      service: 'General',
    },
    {
      id: '69C68E89-06CA-4242-BDB0-AB8500B5F4CA',
      specialty: 'GASTROENTEROLOGIA PEDIATRICA',
      service: 'General TLM',
    },
    {
      id: 'E3F62FC7-7227-4E05-A394-A98A01533BA1',
      specialty: 'PSIQUIATRIA ADULTOS',
      service: 'DBT (Terapia Conductual Dialectica)',
    },
    {
      id: '5B54C186-B419-4965-8D72-A98A00F1E008',
      specialty: 'GENETICA CLINICA',
      service: 'General',
    },
    {
      id: 'DD5F8ACE-8158-4233-9C1E-AB8500B0DC1D',
      specialty: 'CIRUGIA PLASTICA Y REPARADORA',
      service: 'General TLM',
    },
    {
      id: 'C98AA497-1F56-4008-AB72-A98A00F1D557',
      specialty: 'CIRUGIA ONCOLOGICA',
      service: 'General',
    },
    {
      id: '3C15A2A8-4524-420C-A47A-AB8500C4A969',
      specialty: 'OTORRINOLARINGOLOGIA',
      service: 'General TLM',
    },
    {
      id: '35AF33F4-788C-477B-BCAB-AB8500C90EFB',
      specialty: 'INMUNOLOGIA Y ALERGIA ADULTOS',
      service: 'General TLM',
    },
    {
      id: 'F8F6C654-21AA-40C1-B903-A98A00F1E45F',
      specialty: 'HEMATOLOGIA ADULTOS',
      service: 'General',
    },
    {
      id: 'EEDCEED7-E227-4B37-9D1F-AB8500B1770B',
      specialty: 'CIRUGIA VASCULAR Y ENDOVASCULAR',
      service: 'General TLM',
    },
    {
      id: '37ECD2A0-452E-45D0-803C-ABC90176935F',
      specialty: 'MEDICINA DEL VIAJERO',
      service: 'Medicina del Viajero TLM',
    },
    {
      id: '2685F1ED-CE9D-42EE-AF18-AC4700D485B3',
      specialty: 'PSIQUIATRIA PEDIATRICA Y ADOLESCENTE',
      service: 'Trastornos de la Alimentacion TLM',
    },
    {
      id: '643F7AED-DF6A-471A-9E3C-AC6901406B33',
      specialty: 'TRAUMATOLOGIA PEDIATRICA',
      service: 'Tumores Musculoesqueleticos',
    },
    {
      id: '2028EC63-12BF-4DCD-AA25-AB9D00C9FFA2',
      specialty: 'DERMATOLOGIA',
      service: 'General Pediatrica TLM',
    },
    {
      id: 'AA27D6F3-E1A0-4837-8737-A98A00F1EB67',
      specialty: 'MEDICINA DEL VIAJERO',
      service: 'Medicina del Viajero',
    },
    {
      id: '93B6647D-A25E-4D4F-BCBA-A98A00F209DB',
      specialty: 'MEDICINA FAMILIAR DEL NIÑO',
      service: 'Programa Parentalidad Positiva (Triple P)',
    },
    {
      id: 'B4352D32-7C70-465D-952B-B153012912E7',
      specialty: 'DERMATOLOGIA',
      service: 'Dermatologia General Adulto y Niño',
    },
    {
      id: 'A1DD1A2F-299F-41CE-A156-AC130106F961',
      specialty: 'PEDIATRIA',
      service: 'Atencion con Lengua de Señas TLM',
    },
    {
      id: '014E0D80-43D3-4690-9E15-AF5B00D0592E',
      specialty: 'CIRUGIA CARDIACA',
      service: 'Adulto',
    },
    {
      id: '2DA17292-2865-40BC-BC57-ABC9017236EA',
      specialty: 'TRAUMATOLOGIA ADULTO',
      service: 'Traumatologia Deportiva TLM',
    },
    {
      id: '70A63825-3BE2-4C62-A59F-AAA100E77068',
      specialty: 'MEDICINA INTERNA',
      service: 'Cuidados Paliativos',
    },
    {
      id: 'B1383C0A-0178-4C8C-BE1A-A98A00F1CF7F',
      specialty: 'BRONCOPULMONAR PEDIATRICO',
      service: 'General',
    },
    {
      id: '31BCC498-5750-43F6-BAEC-A9A60142BF92',
      specialty: 'PSIQUIATRIA ADULTOS',
      service: 'Trastornos de la Alimentacion',
    },
    {
      id: 'F43C3D6B-02FB-4D94-9D26-A98A00F1D434',
      specialty: 'CIRUGIA DIGESTIVA',
      service: 'General',
    },
    {
      id: '30906E6E-FF69-40EE-8489-ABC10049B23F',
      specialty: 'TRAUMATOLOGIA ADULTO',
      service: 'Rodillas TLM',
    },
    {
      id: '3602ED6F-2C3B-4C7B-8923-AB8500BA0D54',
      specialty: 'INFECTOLOGIA PEDIATRICA',
      service: 'General TLM',
    },
    {
      id: '266195AC-1AA6-4B9A-A905-A9AD012C0958',
      specialty: 'TRAUMATOLOGIA ADULTO',
      service: 'Codo',
    },
    {
      id: 'CA9CC247-9413-4D85-9519-AF5B00D08C62',
      specialty: 'CIRUGIA CARDIACA',
      service: 'Adulto TLM',
    },
    {
      id: 'DEBB21E8-45FF-47D9-85B6-A98A00F1E06B',
      specialty: 'GERIATRIA',
      service: 'General',
    },
    {
      id: '5E4A604E-6181-4519-8749-ACF30119CE30',
      specialty: 'MEDICINA INTERNA',
      service: 'Cuidados Paliativos TLM',
    },
    {
      id: '22B360BC-4B09-47A8-8907-ABC100492429',
      specialty: 'TRAUMATOLOGIA ADULTO',
      service: 'Hombro TLM',
    },
    {
      id: '326D7080-98E8-49BE-ADCB-A98A00F222DB',
      specialty: 'TRAUMATOLOGIA ADULTO',
      service: 'Hombro',
    },
    {
      id: '217D2BA9-301D-47F2-B3FA-AC36010BDE15',
      specialty: 'PSIQUIATRIA ADULTOS',
      service: 'Trastornos de la Alimentacion TLM',
    },
    {
      id: '95F2CE79-2750-4323-AF2A-A98A00F1D6BC',
      specialty: 'CIRUGIA VASCULAR Y ENDOVASCULAR',
      service: 'General',
    },
    {
      id: '7C3C2CA0-8558-4270-B0A4-A9A60142B0B9',
      specialty: 'CIRUGIA GENERAL',
      service: 'General',
    },
    {
      id: '04E3DA4F-EB0E-47AB-8C64-A98A00F1DC0F',
      specialty: 'ENFERMEDADES METABOLICAS',
      service: 'General',
    },
    {
      id: 'F09B7144-34EC-465B-B582-A98A00F208D0',
      specialty: 'PEDIATRIA',
      service: 'Necesidades Especiales',
    },
    {
      id: '8E5D7E87-11AC-4E9C-A365-AB8500C07CE5',
      specialty: 'NUTRICION MEDICA PEDIATRICA',
      service: 'General TLM',
    },
    {
      id: '81725646-C223-4D2A-8353-AB8500C8C30A',
      specialty: 'REUMATOLOGIA ADULTOS',
      service: 'General TLM',
    },
    {
      id: 'D28CE64C-1795-444B-A092-A98A00F1DAE4',
      specialty: 'ENDOCRINOLOGIA PEDIATRICA',
      service: 'General',
    },
    {
      id: '093F2633-6BC7-45A4-AFD3-A98A00F229F0',
      specialty: 'UROLOGIA ADULTOS',
      service: 'Incontinencia Urinaria',
    },
    {
      id: '05638B3B-A072-4B2E-8097-B153012A6FD9',
      specialty: 'DERMATOLOGIA',
      service: 'General Adulto TLM',
    },
    {
      id: 'A3124754-20ED-481B-A3BF-AB8500BC7FA1',
      specialty: 'NEFROLOGIA ADULTOS',
      service: 'General TLM',
    },
    {
      id: '886DE19F-089C-4FB3-84CD-AB8500B8EB9A',
      specialty: 'HEMATOLOGIA ADULTOS',
      service: 'General TLM',
    },
    {
      id: 'D1446AB5-C3D3-4BBD-B42C-AB8500CAC640',
      specialty: 'UROLOGIA PEDIATRICA',
      service: 'General TLM',
    },
    {
      id: '69E3FC99-55D2-4E88-A5CF-ABC1004985D0',
      specialty: 'TRAUMATOLOGIA ADULTO',
      service: 'Caderas TLM',
    },
    {
      id: '8253FC12-9CEA-475C-9AF2-AB9D00C5B192',
      specialty: 'NEUROLOGIA ADULTOS',
      service: 'Temblor y Parkinson TLM',
    },
    {
      id: 'D97329D1-6323-4A5D-8A53-A9A60142B227',
      specialty: 'CIRUGIA ONCOLOGICA',
      service: 'Mamas',
    },
    {
      id: '5D039C02-52D7-4C66-9450-AC9500FD30A7',
      specialty: 'PSIQUIATRIA PEDIATRICA Y ADOLESCENTE',
      service: 'Trastornos de la Alimentacion',
    },
    {
      id: 'F19FBAA7-0BAB-43A4-B45A-A98A00F1DF3A',
      specialty: 'GASTROENTEROLOGIA PEDIATRICA',
      service: 'General',
    },
    {
      id: 'D0BF691F-3275-4305-AE83-AC47014C1A63',
      specialty: 'NUTRICION MEDICA ADULTOS',
      service: 'Trastornos de la Alimentacion TLM',
    },
    {
      id: '77EA288F-1211-45C5-88E5-A98A00F1D5A6',
      specialty: 'CIRUGIA PEDIATRICA',
      service: 'General',
    },
    {
      id: '9E3C327D-AFB7-4059-8E90-AB8500B64C46',
      specialty: 'GENETICA CLINICA',
      service: 'General TLM',
    },
    {
      id: '8DDB7930-AB9D-4FD5-B278-A98A00F1F54B',
      specialty: 'NEUROLOGIA ADULTOS',
      service: 'General',
    },
    {
      id: 'E7AF6A09-36BA-4B1B-9B4A-A98A00F1D906',
      specialty: 'ENDOCRINOLOGIA ADULTOS',
      service: 'General',
    },
    {
      id: '9A3A1C16-5B07-4E65-95EE-A99E01459368',
      specialty: 'MEDICINA FAMILIAR ADULTOS',
      service: 'Cuidados Paliativos',
    },
    {
      id: 'A9CC42AB-A101-488C-B286-AB92003A161D',
      specialty: 'ADOLESCENCIA',
      service: 'General TLM',
    },
    {
      id: 'AC15D803-A297-4498-9706-AB8500ADD0D7',
      specialty: 'CARDIOLOGIA PEDIATRICA',
      service: 'General TLM',
    },
    {
      id: 'D8CF4358-451F-4582-8A01-AC150143AA05',
      specialty: 'RADIOTERAPIA',
      service: 'General',
    },
    {
      id: 'F09A3FF1-4903-44FC-BC52-AB920035B6A4',
      specialty: 'TRAUMATOLOGIA PEDIATRICA',
      service: 'General TLM',
    },
    {
      id: '8658D443-964F-463F-83B3-A98A00F22379',
      specialty: 'TRAUMATOLOGIA ADULTO',
      service: 'Manos',
    },
    {
      id: 'D5EB3246-A26A-4358-8F8F-ABC1004A1368',
      specialty: 'TRAUMATOLOGIA ADULTO',
      service: 'Tumores Musculoesqueleticos TLM',
    },
    {
      id: 'D26553E9-F478-4A32-AA33-AB86016A75A8',
      specialty: 'GERIATRIA',
      service: 'General TLM',
    },
    {
      id: 'D8D83F60-1F28-465B-8568-AF5B00D1ABA2',
      specialty: 'CIRUGIA COLOPROCTOLOGICA',
      service: 'General',
    },
    {
      id: '03DEBD86-B945-4B46-A3E4-A98A00F203A6',
      specialty: 'ONCOLOGIA ADULTOS',
      service: 'General',
    },
    {
      id: '7BFFF2DE-5F19-4F04-88A5-A98A00F204B1',
      specialty: 'OTORRINOLARINGOLOGIA',
      service: 'General',
    },
    {
      id: '8B19256A-C8EA-4E74-ACEB-AB8500B885B1',
      specialty: 'HEMATOLOGIA PEDIATRICA',
      service: 'General TLM',
    },
    {
      id: '4D65037A-904C-472C-ACAB-ABA301556AA2',
      specialty: 'MEDICINA FISICA Y REHABILITACION (FISIATRIA)',
      service: 'Fisiatria TLM',
    },
    {
      id: 'DC19E9C5-5AF0-4BE1-B431-AB8500ACB238',
      specialty: 'BRONCOPULMONAR ADULTOS',
      service: 'General TLM',
    },
    {
      id: '946E9804-DD50-474D-B109-A99E014594BE',
      specialty: 'MEDICINA FISICA Y REHABILITACION (FISIATRIA)',
      service: 'Fisiatria',
    },
    {
      id: 'F641C34A-4AC8-4262-9909-A98A00F2240B',
      specialty: 'TRAUMATOLOGIA ADULTO',
      service: 'Tobillo y Pie',
    },
    {
      id: 'CF577543-E3C9-42D3-B177-AB8500B43608',
      specialty: 'ENDOCRINOLOGIA PEDIATRICA',
      service: 'General TLM',
    },
    {
      id: 'D390807B-0A0B-4E1D-918F-AF5B00D1EA6D',
      specialty: 'CIRUGIA COLOPROCTOLOGICA',
      service: 'General TLM',
    },
    {
      id: 'F9706EB5-5871-44AA-9480-AF5B00D0D706',
      specialty: 'CIRUGIA CARDIACA',
      service: 'Pediatria',
    },
    {
      id: '69ABC542-17C6-4A4C-A9B2-AC78017802E8',
      specialty: 'TRAUMATOLOGIA PEDIATRICA',
      service: 'Tumores Musculoesqueleticos TLM',
    },
    {
      id: '241E9818-60EC-4A8B-9A4E-ABC10049DDC1',
      specialty: 'TRAUMATOLOGIA ADULTO',
      service: 'Tobillo y Pie TLM',
    },
    {
      id: '58B4E9DD-0DAC-45E5-B469-AB8500BD7B37',
      specialty: 'PEDIATRIA',
      service: 'Neonatologia TLM',
    },
    {
      id: '32B8AC55-12DC-45B4-AE59-ACA6011518C0',
      specialty: 'MEDICINA FAMILIAR DEL NIÑO',
      service: 'Programa Parentalidad Positiva (Triple P) TLM',
    },
    {
      id: '089A12CB-EC4C-43CF-B17A-A98A00F1F9B4',
      specialty: 'NUTRICION MEDICA PEDIATRICA',
      service: 'General',
    },
    {
      id: '3FC4A766-DBF6-4AE6-A408-A9A60142B28D',
      specialty: 'CIRUGIA ONCOLOGICA',
      service: 'Tiroides',
    },
    {
      id: 'C2B27958-D77D-4BF4-BCE8-AB8500BDFAF5',
      specialty: 'NEUROCIRUGIA',
      service: 'General TLM',
    },
    {
      id: '4164651C-E6B3-4C18-88F3-AB8500B12BDC',
      specialty: 'CIRUGIA TORAX',
      service: 'General TLM',
    },
    {
      id: '118F2E7C-42D2-43F3-B7BA-A98A00F1E8F3',
      specialty: 'MANEJO DE DOLOR NO ONCOLOGICO',
      service: 'Manejo de Dolor',
    },
    {
      id: '134AC1FF-20A5-4BC9-A547-ABC10048CEB7',
      specialty: 'TRAUMATOLOGIA ADULTO',
      service: 'Manos TLM',
    },
    {
      id: 'A54D8B7B-6830-4162-A005-A98A0153384C',
      specialty: 'MEDICINA FAMILIAR ADULTOS',
      service: 'Psiquiatria de Enlace',
    },
    {
      id: '2C99C8BE-5549-4C76-9C3F-AB9D00C61CFC',
      specialty: 'NEUROLOGIA ADULTOS',
      service: 'Trastorno del Sueño TLM',
    },
    {
      id: '9DCDEAC7-F1B5-45EE-A545-A99E0145A283',
      specialty: 'NUTRICION MEDICA ADULTOS',
      service: 'General',
    },
    {
      id: 'D515A82C-D3EB-4B17-9AE5-AB8500B58031',
      specialty: 'GASTROENTEROLOGIA ADULTOS',
      service: 'General TLM',
    },
    {
      id: 'B942A550-89D3-4D4D-95FA-A9A60142C0DB',
      specialty: 'REUMATOLOGIA PEDIATRICA',
      service: 'General',
    },
    {
      id: '8A224F09-A88C-44FE-805C-A98A00F22A8B',
      specialty: 'UROLOGIA PEDIATRICA',
      service: 'General',
    },
    {
      id: '9730C044-4843-4D7F-A95A-A98A00F1E62C',
      specialty: 'HEMATOLOGIA PEDIATRICA',
      service: 'General',
    },
    {
      id: '9A36F63B-50BC-46A3-A40B-AB8500C45A51',
      specialty: 'ONCOLOGIA ADULTOS',
      service: 'General TLM',
    },
    {
      id: 'BD7FF297-B4E5-4BF9-B0E8-A98A00F1F3DD',
      specialty: 'PEDIATRIA',
      service: 'Neonatologia',
    },
    {
      id: '61E59F22-2452-4F80-8138-AB8500C6FA2A',
      specialty: 'PSIQUIATRIA ADULTOS',
      service: 'General TLM',
    },
    {
      id: '21E1CEB8-08BB-46E9-8783-AB4F012043F0',
      specialty: 'NEUROLOGIA ADULTOS',
      service: 'Temblor y Parkinson',
    },
    {
      id: '277A1C3B-F105-4BAB-A6EF-AC9500FF9C05',
      specialty: 'NUTRICION MEDICA ADULTOS',
      service: 'Trastornos de la Alimentacion',
    },
    {
      id: 'A3FD1F17-2DD3-4DB8-8FB3-AB8500AD20F2',
      specialty: 'BRONCOPULMONAR PEDIATRICO',
      service: 'General TLM',
    },
    {
      id: '5036CA2F-E199-480E-9DBB-A98A00F1D4E6',
      specialty: 'CIRUGIA MENOR',
      service: 'General',
    },
    {
      id: 'C9915CC6-26FE-4982-A462-AC0200D863C9',
      specialty: 'GASTROENTEROLOGIA ADULTOS',
      service: 'Hepatologia TLM',
    },
    {
      id: 'D1DBC7CB-7730-4335-8E82-A98A00F1E859',
      specialty: 'INMUNOLOGIA Y ALERGIA PEDIATRICA',
      service: 'General',
    },
    {
      id: 'E32E8B28-2C45-4DDC-9867-AF5B00D2D60D',
      specialty: 'MEDICINA DEPORTIVA',
      service: 'General',
    },
    {
      id: 'C9B7B4C4-3118-4949-BC70-B08B00EB9544',
      specialty: 'MEDICINA INTERNA',
      service: 'General',
    },
    {
      id: '1CBE0A55-43B5-411D-A1F5-A98A00F1F1A2',
      specialty: 'NEFROLOGIA ADULTOS',
      service: 'General',
    },
    {
      id: '59E3772D-6358-409A-9AA2-AB8500AD7B12',
      specialty: 'CARDIOLOGIA ADULTOS',
      service: 'General TLM',
    },
    {
      id: 'C783181F-2690-4839-B008-AB8500C9F91A',
      specialty: 'TRAUMATOLOGIA ADULTO',
      service: 'General TLM',
    },
    {
      id: '7C6A2F3A-099B-4AEA-91F5-A99E01459E72',
      specialty: 'DIABETOLOGIA ADULTOS',
      service: 'General',
    },
    {
      id: '29790DEE-E266-4619-B944-AB14014FBB31',
      specialty: 'CIRUGIA DIGESTIVA',
      service: 'General TLM',
    },
    {
      specialty: 'Enfermeria',
      service: 'Clinica de Lactancia',
      id: 'B16F08EE-1133-41B9-AF81-A98A0153307A',
    },
    {
      specialty: 'Enfermeria',
      service: 'Enfermedades Cronicas Adulto',
      id: '43ACFA3D-DDB7-4088-8F91-A98A0153333E',
    },
    {
      specialty: 'Nutricionista',
      service: "Diabetes",
      id: "7B97826B-EE8D-45E9-AB39-A9AC01013305",
      fonasaIds: ["2602001"],
      imedIds: ["2602001"]
    },
    {
      specialty: 'Nutricionista',
      service: "Diabetes Pediatrico",
      id: "6871C9E9-7365-4979-9155-A9D70135C5E6",
      fonasaIds: ["2602001"],
      imedIds: ["2602001"]
    },
    {
      specialty: 'Nutricionista',
      service: "Embarazo",
      id: "76BAB1B2-921A-40EA-BDF3-A98A00F1FD21",
      fonasaIds: ["2602001"],
      imedIds: ["2602001"]
    },
    {
      specialty: 'Nutricionista',
      service: "Enfermedad Renal Cronica",
      id: "BA94F113-410A-4A5D-9B18-AB7D013BE4CA",
      fonasaIds: ["2602001"],
      imedIds: ["2602001"]
    },
    {
      specialty: 'Nutricionista',
      service: "Enfermedades Gastrointestinales",
      id: "84A99D55-35D9-4BFD-A6EE-AB7D0148B82F",
      fonasaIds: ["2602001"],
      imedIds: ["2602001"]
    },
    {
      specialty: 'Nutricionista',
      service: "General Adulto",
      id: "FFD1DA4E-F1D6-4D6D-AE7E-A98A00F1FCA2",
      fonasaIds: ["2602001"],
      imedIds: ["2602001"]
    },
    {
      specialty: 'Nutricionista',
      service: "General Pediatrico",
      id: "A3DA2460-DB3C-46B3-B378-A98A00F1FDA4",
      fonasaIds: ["2602001"],
      imedIds: ["2602001"]
    },
    {
      specialty: 'Nutricionista',
      service: " Medicina Deportiva",
      id: "F9339C66-F7C0-495C-9732-A9AF0129780D",
      fonasaIds: ["2602001"],
      imedIds: ["2602001"]
    },
    {
      specialty: 'Nutricionista',
      service: "Obesidad",
      id: "870681CF-A0CD-4C5B-8309-A98A00F1FE1A",
      fonasaIds: ["2602001"],
      imedIds: ["2602001"]
    },
    {
      specialty: 'Nutricionista',
      service: "Oncologia",
      id: "AD4AAAEA-C68A-421C-8DB9-A9BE016081AC",
      fonasaIds: ["2602001"],
      imedIds: ["2602001"]
    },
    {
      specialty: 'Nutricionista',
      service: "Trastorno Alimentacion",
      id: "3026041F-B9A9-4842-927A-AC9500FE4F24",
      fonasaIds: ["2602001"],
      imedIds: ["2602001"]
    },
    {
      specialty: 'Programa Cefaleas',
      id: 'cefaleas',
      service: "UC",
      fonasaIds: ["0101209"],
      imedIds: ["0101825", "0101001"]
    },
    {
      specialty: 'Psicologia Adulto',
      service: "Cuidados Paliativos",
      id: "B55D5A8D-9300-44E5-8A23-A9B1006C3C64",
      fonasaIds: ["0902001"],
      imedIds: ["0902001"],
    },
    {
      specialty: 'Psicologia Adulto',
      service: "General",
      id: "096EB5DD-935B-4BEA-8E7A-A98A00F20AEB",
      fonasaIds: ["0902001"],
      imedIds: ["0902001"],
    },
    {
      specialty: 'Psicologia Adulto',
      service: "Geriatria",
      id: "1B173B76-1AD2-4F40-B8E6-A98A00F20D1D",
      fonasaIds: ["0902001"],
      imedIds: ["0902001"],
    },
    {
      specialty: 'Psicologia Adulto',
      service: "Manejo Dolor No Oncologico",
      id: "306B7921-E18B-4550-AF34-A98A00F20DAA",
      fonasaIds: ["0902001"],
      imedIds: ["0902001"],
    },
    {
      specialty: 'Psicologia Adulto',
      service: "Medicina Deportiva",
      id: "A8FBC660-3779-40B5-854B-A9A60142BD77",
      fonasaIds: ["0902001"],
      imedIds: ["0902001"],
    },
    {
      specialty: 'Psicologia Adulto',
      service: "Neuropsicologia",
      id: "B14A8CBB-F0EB-4923-8E55-A98A00F20E37",
      fonasaIds: ["0902001"],
      imedIds: ["0902001"],
    },
    {
      specialty: 'Psicologia Adulto',
      service: "Obstetricia",
      id: "346CF161-5B03-4551-8D8F-A98A00F20F50",
      fonasaIds: ["0902001"],
      imedIds: ["0902001"],
    },
    {
      specialty: 'Psicologia Adulto',
      service: "Oncologia",
      id: "DDE53459-6FF9-4B58-8E21-A9B1006C69BE",
      fonasaIds: ["0902001"],
      imedIds: ["0902001"],
    },
    {
      specialty: 'Psicologia Adulto',
      service: "Programa Obesidad",
      id: "9ADD62E2-4CE3-41C8-B20B-A98A01533A84",
      fonasaIds: ["0902001"],
      imedIds: ["0902001"],
    },
    {
      specialty: 'Psicologia Adulto',
      service: "Terapia Familiar",
      id: "A00A7EAD-E8DF-4C28-9855-A98A00F211EA",
      fonasaIds: ["0902001"],
      imedIds: ["0902001"],
    },
    {
      specialty: 'Psicologia Adulto',
      service: "Trastorno Del Sueño",
      id: "DE5693D7-106C-409B-8689-A98A00F20C0D",
      fonasaIds: ["0902001"],
      imedIds: ["0902001"],
    },
    {
      specialty: 'Psicologia Pediatrica Y Adolescente',
      service: "General",
      id: "94816ED5-8974-45E7-A21D-A98A0153396A",
      fonasaIds: ["0902001"],
      imedIds: ["0902001"],
    },
    {
      specialty: 'Psicologia Pediatrica Y Adolescente',
      service: "Trastornos De La Alimentacion",
      id: "C28572F5-6B4E-4CE5-8BFB-AE6A00F2F0E4",
      fonasaIds: ["0902001"],
      imedIds: ["0902001"],
    },
    {
      specialty: 'Unidad Salud Mental Integral y Recepcion',
      service: "(USMI-R)",
      id: "saludmentalintegral",
      fonasaIds: ["0101001", "0101305", "0101814"],
      imedIds: ["0101001", "0101849", "0101814"]
    },
    {
      specialty: 'Medicina Familiar Adultos',
      service: "General",
      id: "medicinafamiliaradultosgeneral",
      fonasaIds: ["0101305"],
      imedIds: ["0101849", "0101001", "0101814"]
    },
    {
      specialty: 'Medicina Familiar Del Niño',
      service: "General",
      id: "medicinafamiliarninogeneral",
      fonasaIds: ["0108305"],
      imedIds: ["0101849", "0108227"]
    },
    {
      specialty: 'Medicina General',
      service: "General",
      id: "medicinageneralgeneral",
      fonasaIds: ["0101001"],
      imedIds: ["0101001", "0101814"]
    },
    {
      specialty: 'Pediatria',
      service: "General",
      id: "pediatriageneral",
      fonasaIds: ["0101309"],
      imedIds: ["0101803", "0101849", "0101305"]
    },
  ],
  noIh: [
    {
      id: '1',
      specialty: 'Urgencia Hospital Clinico Marcoleta',
      service: '',
    },
    {
      id: '2',
      specialty: 'Urgencia Clinica San Carlos',
      service: '',
    },
    {
      id: '3',
      specialty: 'Otra Especialidad',
      service: '',
    },
    {
      id: '5',
      specialty: 'Nutricionista',
      service: '',
    },
    {
      id: '6',
      specialty: 'Psicologia',
      service: '',
    },
    {
      id: '7',
      specialty: 'Terapia Ocupacional',
      service: '',
    },
    {
      id: '4841B364-2949-491A-BDED-A98A00F1DCD9',
      specialty: 'Fonoaudiología',
      service: '',
    },
    {
      id: '9',
      specialty: 'Psicopedagogia',
      service: '',
    },
    {
      id: '10',
      specialty: 'Seguimiento Orientacion Quirurgica',
      service: '',
    },
    {
      id: '11',
      specialty: 'Enfermeria',
      service: '',
    },
    {
      id: '12',
      specialty: 'Urgencia Externa',
      service: '',
    },
    {
      id: '13',
      specialty: 'Pre Dialisis',
      service: '',
    },
    {
      id: '14',
      specialty: 'Programa de insuficiencia Cardiaca',
      service: '',
    },
    {
      id: '15',
      specialty: 'Programa Cefaleas UC',
      service: '',
    },
    {
      id: '16',
      specialty: 'Unidad Salud Mental Integral y Recepcion (USMI-R)',
      service: '',
    },
    {
      specialty: 'Kinesiología',
      id: '946024DA-B3A7-4B58-B985-AB3B00BF5413',
      service: '',
    },
    {
      id: '17',
      specialty: 'Psiquiatría de Enlace y Medicina Psicosomática Ambulatoria (PEMP Ambulatorio)',
      service: '',
    },
  ],
};
