import {Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {MENU_OPTIONS} from '@shared/constants/menu.constant';
import {KEY_PROFESSIONAL_STORE} from '@store/store-keys';
import {AppState} from '@store/app.reducers';
import {Subject} from 'rxjs';
import {distinctUntilChanged, filter, map, take, takeUntil, tap} from 'rxjs/operators';
import {ActionsButtons} from '@core/types/actionButtons.type';

const BUTTONS_ACTIONS = {
  create: 'C',
  read: 'R',
  update: 'U',
  delete: 'D',
  duplicar: 'C',
  ver: 'R',
  'ver-documento': 'R',
  'ver-imagenes': 'R',
  editar: 'U',
  eliminar: 'D',
  'pre-aprovar': 'U',
  rechazar: 'U',
  open: 'U',
  closed: 'U',
};

@Directive({
  selector: '[appShowInAllowedModules]',
})
export class ShowInAllowedModulesDirective implements OnInit, OnDestroy {
  @Input('appShowInAllowedModules') actionButton?: ActionsButtons | string;

  destroy = new Subject();

  constructor(
    private store: Store<AppState>,
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    const action = BUTTONS_ACTIONS[this.actionButton.toLocaleLowerCase()];

    const modules = [];
    MENU_OPTIONS.forEach((app) => modules.push(...app.modules));
    const {sk} = modules.find((module) => module.path === this.router.url);

    this.store
      .select(KEY_PROFESSIONAL_STORE)
      .pipe(
        takeUntil(this.destroy),
        distinctUntilChanged(),
        filter(({isLoaded}) => isLoaded),
        map(({professional}) => professional.access),
        map((access) => access.find((module) => module.option === sk)),
        filter((module) => module !== undefined),
        take(1),
        map((module) => Boolean(module.allow.includes(action))),
        tap((isAllowed) => this.createOrDeleteComponent(isAllowed)),
      )
      .subscribe();
  }

  createOrDeleteComponent(isAllowed: boolean) {
    if (isAllowed) this.viewContainerRef.createEmbeddedView(this.templateRef);
    else this.viewContainerRef.clear();
  }

  ngOnDestroy(): void {
    this.destroy.next('next');
    this.destroy.complete();
  }
}
