import {Nurse} from '@clinical/oncology/interfaces/roadmap.interface';
import {ROOT_ROUTES} from '../../../app-routing.module';
import {CLINICAL_ROUTES} from '@clinical/clinical-routes.module';
import {ONCOLOGY_ROUTES} from '@clinical/oncology/oncology.routes.module';

export const BASE_PATH = 'quimioterapia-hoja-ruta-ceca';

export const HISTORY_PROFESSIONAL_ROUTE = `/${ROOT_ROUTES.clinical}/${CLINICAL_ROUTES.oncology}/${ONCOLOGY_ROUTES.historyProfessional}`;

export const TAC_AGREEMENTS = [
  {name: 'CAEC', isChecked: false, hold: false},
  {name: 'AUGE/GES', isChecked: false, hold: false},
  {name: 'Isapre', isChecked: false, hold: false},
  {name: 'Fonasa', isChecked: false, hold: false},
  {name: 'Segundo Prestador', isChecked: false, hold: false},
  {name: 'Otro', isChecked: false, hold: false},
];

export const TAC_TREATMENT = [
  {name: 'Mama', isChecked: false, hold: false},
  {name: 'Tórax', isChecked: false, hold: false},
  {name: 'Abdomen', isChecked: false, hold: false},
  {name: 'Cabeza y cuello', isChecked: false, hold: false},
  {name: 'Pelvis', isChecked: false, hold: false},
  {name: 'Extremidades', isChecked: false, hold: false},
  {name: 'Cerebro', isChecked: false, hold: false},
  {name: 'Craneoespinal', isChecked: false, hold: false},
];

export const TAC_VEJIGA = [
  {name: 'Llena', isChecked: false, hold: false},
  {name: 'Normal', isChecked: false, hold: false},
  {name: 'Vacía', isChecked: false, hold: false},
];

export const TAC_OPTIONS = [
  {name: 'Llena', isChecked: false, hold: false},
  {name: 'Normal', isChecked: false, hold: false},
  {name: 'Vacía', isChecked: false, hold: false},
];

export const ONCOLOGY = {
  'indicación de radioterapia para presupuesto': {
    section: 'indicación de radioterapia para presupuesto',
    path: '/oncologia/radioterapia',
  },
  'orden medica tac de simulación': {
    section: 'Orden Medica TAC de Simulación',
    path: '/oncologia/tac',
  },
  'ruta para pacientes oncológicos': {
    section: 'Ruta CECA para pacientes oncológicos',
    path: '/oncologia/quimioterapia-hoja-ruta-ceca',
  },
};

export const RADIOTHERAPY_CONVENTION = [
  'HOSPITAL SÓTERO DEL RÍO',
  'HOSPITAL CALVO MACKENA',
  'HOSPITAL SALVADOR',
  'CLÍNICA SANTA MARÍA',
  'OTRO',
];

export const RADIOTHERAPY_CENTER = [
  {name: 'Hospital Marcoleta', isChecked: false, hold: false},
  {name: 'Clínica San Carlos', isChecked: false, hold: false},
];

export const ROADMAP_CENTERS: { id: string; name: string }[] = [
  {id: 'csc', name: 'Clínica San Carlos'},
  {id: 'cem', name: 'Marcoleta'},
];

export const ROADMAP_TREATMENT_PLACE = [
  {id: 'b76b793a-543d-4ce4-92a5-a974012ef7bf', name: 'Centro del Cáncer Diagonal Paraguay 319, Santiago'},
  {id: '52e43c90-8ab7-4e34-afcb-a96f0106bbd1', name: 'Centro de Cáncer Clínica San Carlos de Apoquindo'},
];

export const ROADMAP_MODALITY_TYPES = ['ambulatorio', 'hospitalario'];
export const ROADMAP_CATHETER_TYPES = ['reservorio', 'transitorio'];

export const ROADMAP_NURSES: Nurse[] = [
  {fullName: 'Paola Pavez Alvarado', phone: '942740116', email: 'ppavez@ucchristus.cl'},
  {fullName: 'Lidia Medina Araya', phone: '971413850', email: 'lmedina@ucchristus.cl'},
  {fullName: 'Constanza Bolivar Alvear', phone: '932293058', email: 'cbolivara@ucchristus.cl'},
  {fullName: 'Jacqueline Quezada Flores', phone: '933769478', email: 'jquezadaf@ucchristus.cl'},
  {fullName: 'Silvia Palma Correa', phone: '978073560', email: 'spalma@ucchristus.cl'},
  {fullName: 'Patricia Pérez Acevedo', phone: '966762417', email: 'pperez@ucchristus.cl'},
  {fullName: 'Katherine Soto Donoso', phone: '957794549', email: 'ksotod@ucchristus.cl'},
  {fullName: 'Silvana Arellano Villarroel', phone: '994376396', email: 'sarellano@ucchristus.cl'},
  {fullName: 'José Manuel Valencia ', phone: '938605846', email: 'jvalenciau@ucchristus.cl'},
  {fullName: 'Paola Paredes Hernández', phone: '931029128', email: 'pparedesh@ucchristus.cl'},
  {fullName: 'María Fernanda Saavedra Fonseca', phone: '931913024', email: 'msaavedraf@ucchristus.cl'},
];

export const MAIL_BASE = ['enfermerasradioterapia@ucchristus.cl'];

// Esta no se está utilizando se encuentra en  el file notification-emails.const.ts
export const MAIL_TAC = [
  'adaza@ucchristus.cl',
  'elopezc@ucchristus.cl',
  'fgomezs@ucchristus.cl',
  'otorrejon@ucchristus.cl',
  'consultasceca1@ucchristus.cl',
  'simulacionesceca@ucchristus.cl',
  'tacsimulacion@ucchristus.cl',
  'marcea@ucchristus.cl'
];
export const MAIL_RADIOTHERAPY_MARCOLETA = [
  'enfermerasradioterapia@ucchristus.cl',
  'enfermerascanceruc@ucchristus.cl',
  'admisionceca@ucchristus.cl',
  'tacsimulacion@ucchristus.cl'
];

export const MAIL_RADIOTHERAPY_CSC = [
  'ltudela@ucchristus.cl',
  'adaza@ucchristus.cl',
  'elopezc@ucchristus.cl',
  'fgomezs@ucchristus.cl',
  'otorrejon@ucchristus.cl',
  'calarcona@ucchristus.cl',
  'dleonicio@ucchristus.cl',
  'vrosales@ucchristus.cl',
  'tacsimulacion@ucchristus.cl',
  'oncologiacsc@ucchristus.cl',
  'marcea@ucchristus.cl'
];

export const NURSES_SC_MAILS = [
  'pparedesh@ucchristus.cl',
  'mcasasdv@ucchristus.cl',
  'calarcona@ucchristus.cl',
  'adaza@ucchristus.cl',
  'otorrejon@ucchristus.cl',
];

export const NURSES_MC_MAILS = ['admisionceca@ucchristus.cl'];

export const ROADMAP_RECOMMENDATIONS = {
  cem: [
    'Para solicitud de Presupuesto de Quimioterapia, dirigirse directamente al Piso -1 Asesor de cobertura.',
    'En caso de tener un GES y/o CAEC activo en la Red de Salud UC Christus, ' +
    'debe pasar directamente con Equipo de Enfermeras de Orientación piso -1',
    'Si su atención es bajo convenio Fonasa Segundo Prestador, por favor dirigirse a la Unidad GES del Hospital Clínico, ' +
    'Marcoleta 367, Piso 1.',
    'En caso de alguna inquietud administrativa del proceso, por favor contactarse a orientacioncanceruc@ucchristus.cl',
    'En caso de alguna inquietud clínica del proceso, por favor contactarse con su Enfermera Coordinadora.',
  ],
  csc: [
    'Una vez que el médico le entregue esta hoja, diríjase a la recepción de las consultas del piso -2 y ' +
    'se la entrega a la ejecutiva, importante que usted agregue su correo y teléfono para contactarle.',
    'Posteriormente recibirá la información sobre presupuesto en su correo y será contactado por nuestro ' +
    'equipo para guiarlo y asesorarlo en coberturas de plan de salud y/o seguros complementarios.',
    'En caso de dudas o retraso en la comunicación usted puede contactar a Claudia Alarcon Andrade ' +
    'calarcona@ucchristus.cl Fono: 229437033',
  ],
};

export const ROADMAP_TREATMENT = [
  {
    "DIAGNOSTIC_LIST": "ANO",
    "COMPLEJA IMRT/VMAT": { "list": ["P002517", "P002800"] },
    "ESTANDAR CONFORMACIONAL": { "list": ["P002497", "P002801"] },
    "ALTAMENTE COMPLEJA /SBRT": { "list": ["P002799"] },
    "CONVENCIONAL": { "list": ["P002492", "P002802"] }
  },
  {
    "DIAGNOSTIC_LIST": "BAZO",
    "COMPLEJA IMRT/VMAT": { "list": ["P002517", "P002800"] },
    "ESTANDAR CONFORMACIONAL": { "list": ["P002497", "P002801"] },
    "ALTAMENTE COMPLEJA /SBRT": { "list": ["P002799"] },
    "CONVENCIONAL": { "list": ["P002492", "P002802"] }
  },
  {
    "DIAGNOSTIC_LIST": "CABEZA CUELLO",
    "COMPLEJA IMRT/VMAT": { "list": ["P002518", "P002800"] },
    "ESTANDAR CONFORMACIONAL": { "list": ["P002498", "P002801"] },
    "ALTAMENTE COMPLEJA /SBRT": { "list": ["P002799"] },
    "CONVENCIONAL": { "list": ["P002492", "P002802"] }
  },
  {
    "DIAGNOSTIC_LIST": "COLANGIOCARCINOMA",
    "COMPLEJA IMRT/VMAT": { "list": ["P002517", "P002800"] },
    "ESTANDAR CONFORMACIONAL": { "list": ["P002497", "P002801"] },
    "ALTAMENTE COMPLEJA /SBRT": { "list": ["P002799"] },
    "CONVENCIONAL": { "list": ["P002492", "P002802"] }
  },
  {
    "DIAGNOSTIC_LIST": "COLON",
    "COMPLEJA IMRT/VMAT": { "list": ["P002517", "P002800"] },
    "ESTANDAR CONFORMACIONAL": { "list": ["P002497", "P002801"] },
    "ALTAMENTE COMPLEJA /SBRT": { "list": ["P002799"] },
    "CONVENCIONAL": { "list": ["P002492", "P002802"] }
  },
  {
    "DIAGNOSTIC_LIST": "COMPRESION MEDULAR",
    "COMPLEJA IMRT/VMAT": { "list": ["P002800"] },
    "ESTANDAR CONFORMACIONAL": { "list": ["P002801"] },
    "CONVENCIONAL": { "list": ["P002492", "P002802"] }
  },
  {
    "DIAGNOSTIC_LIST": "CORDOMA",
    "COMPLEJA IMRT/VMAT": { "list": ["P002526", "P002800"] },
    "ESTANDAR CONFORMACIONAL": { "list": ["P002513", "P002801"] },
    "ALTAMENTE COMPLEJA /SBRT": { "list": ["P002799"] },
    "CONVENCIONAL": { "list": ["P002492", "P002802"] }
  },
  {
    "DIAGNOSTIC_LIST": "CRANEOFARINGIOMA",
    "COMPLEJA IMRT/VMAT": { "list": ["P002526", "P002800"] },
    "ESTANDAR CONFORMACIONAL": { "list": ["P002513", "P002801"] },
    "ALTAMENTE COMPLEJA /SBRT": { "list": ["P002799"] },
    "CONVENCIONAL": { "list": ["P002492", "P002802"] }
  },
  {
    "DIAGNOSTIC_LIST": "CUELLO UTERINO",
    "COMPLEJA IMRT/VMAT": { "list": ["P002522", "P002800"] },
    "ESTANDAR CONFORMACIONAL": { "list": ["P002509", "P002801"] },
    "ALTAMENTE COMPLEJA /SBRT": { "list": ["P002799"] },
    "CONVENCIONAL": { "list": ["P002492", "P002802"] },
    "BRAQUITERAPIA": { "list": ["P001532", "P002798"] }
  },
  {
    "DIAGNOSTIC_LIST": "DERMATOFIBROSARCOMA",
    "COMPLEJA IMRT/VMAT": { "list": ["P002525", "P002800"] },
    "ESTANDAR CONFORMACIONAL": { "list": ["P002512", "P002801"] },
    "ALTAMENTE COMPLEJA /SBRT": { "list": ["P002799"] },
    "CONVENCIONAL": { "list": ["P002492", "P002802"] }
  },
  {
    "DIAGNOSTIC_LIST": "DOLOR INTRATABLE (hosp)",
    "COMPLEJA IMRT/VMAT": { "list": ["P002800"] },
    "ESTANDAR CONFORMACIONAL": { "list": ["P002801"] },
    "CONVENCIONAL": { "list": ["P002492", "P002802"] }
  },
  {
    "DIAGNOSTIC_LIST": "DUODENO",
    "COMPLEJA IMRT/VMAT": { "list": ["P002517", "P002800"] },
    "ESTANDAR CONFORMACIONAL": { "list": ["P002497", "P002801"] },
    "ALTAMENTE COMPLEJA /SBRT": { "list": ["P002799"] },
    "CONVENCIONAL": { "list": ["P002492", "P002802"] }
  },
  {
    "DIAGNOSTIC_LIST": "ENDOMETRIO",
    "COMPLEJA IMRT/VMAT": { "list": ["P002522", "P002800"] },
    "ESTANDAR CONFORMACIONAL": { "list": ["P002509", "P002801"] },
    "ALTAMENTE COMPLEJA /SBRT": { "list": ["P002799"] },
    "CONVENCIONAL": { "list": ["P002492", "P002802"] }
  },
  {
    "DIAGNOSTIC_LIST": "EPENDIMOMA",
    "COMPLEJA IMRT/VMAT": { "list": ["P002526", "P002800"] },
    "ESTANDAR CONFORMACIONAL": { "list": ["P002513", "P002801"] },
    "ALTAMENTE COMPLEJA /SBRT": { "list": ["P002799"] },
    "CONVENCIONAL": { "list": ["P002492", "P002802"] }
  },
  {
    "DIAGNOSTIC_LIST": "ESOFAGO",
    "COMPLEJA IMRT/VMAT": { "list": ["P002514", "P002515", "P002800"] },
    "ESTANDAR CONFORMACIONAL": { "list": ["P002494", "P002495", "P002801"] },
    "ALTAMENTE COMPLEJA /SBRT": { "list": ["P002799"] },
    "CONVENCIONAL": { "list": ["P002492", "P002802"] }
  },
  {
    "DIAGNOSTIC_LIST": "GASTRICO",
    "COMPLEJA IMRT/VMAT": { "list": ["P002517", "P002800"] },
    "ESTANDAR CONFORMACIONAL": { "list": ["P002497", "P002801"] },
    "ALTAMENTE COMPLEJA /SBRT": { "list": ["P002799"] },
    "CONVENCIONAL": { "list": ["P002492", "P002802"] }
  },
  {
    "DIAGNOSTIC_LIST": "GERMINOMA SNC",
    "COMPLEJA IMRT/VMAT": { "list": ["P002526", "P002800"] },
    "ESTANDAR CONFORMACIONAL": { "list": ["P002513", "P002801"] },
    "ALTAMENTE COMPLEJA /SBRT": { "list": ["P002799"] },
    "CONVENCIONAL": { "list": ["P002492", "P002802"] }
  },
  {
    "DIAGNOSTIC_LIST": "GLIOMA",
    "COMPLEJA IMRT/VMAT": { "list": ["P002518", "P002800"] },
    "ESTANDAR CONFORMACIONAL": { "list": ["P002498", "P002801"] },
    "ALTAMENTE COMPLEJA /SBRT": { "list": ["P002799"] },
    "CONVENCIONAL": { "list": ["P002492", "P002802"] }
  },
  {
    "DIAGNOSTIC_LIST": "GLOMUS",
    "COMPLEJA IMRT/VMAT": { "list": ["P002526", "P002800"] },
    "ESTANDAR CONFORMACIONAL": { "list": ["P002513", "P002801"] },
    "ALTAMENTE COMPLEJA /SBRT": { "list": ["P002799"] },
    "CONVENCIONAL": { "list": ["P002492", "P002802"] }
  },
  {
    "DIAGNOSTIC_LIST": "HEMANGIOMA ORBITARIO",
    "COMPLEJA IMRT/VMAT": { "list": ["P002526", "P002800"] },
    "ESTANDAR CONFORMACIONAL": { "list": ["P002513", "P002801"] },
    "ALTAMENTE COMPLEJA /SBRT": { "list": ["P002799"] },
    "CONVENCIONAL": { "list": ["P002492", "P002802"] }
  },
  {
    "DIAGNOSTIC_LIST": "HEMANGIOMA OSEO",
    "COMPLEJA IMRT/VMAT": { "list": ["P002525", "P002800"] },
    "ESTANDAR CONFORMACIONAL": { "list": ["P002512", "P002801"] },
    "ALTAMENTE COMPLEJA /SBRT": { "list": ["P002799"] },
    "CONVENCIONAL": { "list": ["P002492", "P002802"] }
  },
  {
    "DIAGNOSTIC_LIST": "HEMORRAGIA",
    "COMPLEJA IMRT/VMAT": { "list": ["P002800"] },
    "ESTANDAR CONFORMACIONAL": { "list": ["P002801"] },
    "CONVENCIONAL": { "list": ["P002492", "P002802"] }
  },
  {
    "DIAGNOSTIC_LIST": "HEPATOCARCINOMA",
    "COMPLEJA IMRT/VMAT": { "list": ["P002517", "P002800"] },
    "ESTANDAR CONFORMACIONAL": { "list": ["P002497", "P002801"] },
    "ALTAMENTE COMPLEJA /SBRT": { "list": ["P002799"] },
    "CONVENCIONAL": { "list": ["P002492", "P002802"] }
  },
  {
    "DIAGNOSTIC_LIST": "HIPOFISIS",
    "COMPLEJA IMRT/VMAT": { "list": ["P002526", "P002800"] },
    "ESTANDAR CONFORMACIONAL": { "list": ["P002513", "P002801"] },
    "ALTAMENTE COMPLEJA /SBRT": { "list": ["P002799"] },
    "CONVENCIONAL": { "list": ["P002492", "P002802"] }
  },
  {
    "DIAGNOSTIC_LIST": "HISTIOCITOSIS",
    "COMPLEJA IMRT/VMAT": { "list": ["P002526", "P002800"] },
    "ESTANDAR CONFORMACIONAL": { "list": ["P002513", "P002801"] },
    "ALTAMENTE COMPLEJA /SBRT": { "list": ["P002799"] },
    "CONVENCIONAL": { "list": ["P002492", "P002802"] }
  },
  {
    "DIAGNOSTIC_LIST": "HOMBRO DOLOROSO",
    "CONVENCIONAL": { "list": ["P002802"] }
  },
  {
    "DIAGNOSTIC_LIST": "LEUCEMIA",
    "COMPLEJA IMRT/VMAT": { "list": ["P002523", "P001588", "P002800"] },
    "ESTANDAR CONFORMACIONAL": { "list": ["P002510", "P002801"] },
    "ALTAMENTE COMPLEJA /SBRT": { "list": ["P002799"] },
    "CONVENCIONAL": { "list": ["P002492", "P002802"] }
  },
  {
    "DIAGNOSTIC_LIST": "LINFOMA",
    "COMPLEJA IMRT/VMAT": { "list": ["P002524", "P002800"] },
    "ESTANDAR CONFORMACIONAL": { "list": ["P002511", "P002801"] },
    "ALTAMENTE COMPLEJA /SBRT": { "list": ["P002799"] },
    "CONVENCIONAL": { "list": ["P002492", "P002802"] }
  },
  {
    "DIAGNOSTIC_LIST": "MAMA",
    "COMPLEJA IMRT/VMAT": { "list": ["P002516", "P002800"] },
    "ESTANDAR CONFORMACIONAL": { "list": ["P002496", "P002801"] },
    "ALTAMENTE COMPLEJA /SBRT": { "list": ["P002799"] },
    "CONVENCIONAL": { "list": ["P002492", "P002802"] }
  },
  {
    "DIAGNOSTIC_LIST": "MAV",
    "COMPLEJA IMRT/VMAT": { "list": ["P002526", "P002800"] },
    "ESTANDAR CONFORMACIONAL": { "list": ["P002513", "P002801"] },
    "ALTAMENTE COMPLEJA /SBRT": { "list": ["P002799"] },
    "CONVENCIONAL": { "list": ["P002492", "P002802"] }
  },
  {
    "DIAGNOSTIC_LIST": "MEDULOBLASTOMA",
    "COMPLEJA IMRT/VMAT": { "list": ["P002526", "P002800"] },
    "ESTANDAR CONFORMACIONAL": { "list": ["P002513", "P002801"] },
    "ALTAMENTE COMPLEJA /SBRT": { "list": ["P002799"] },
    "CONVENCIONAL": { "list": ["P002492", "P002802"] }
  },
  {
    "DIAGNOSTIC_LIST": "MELANOMA",
    "COMPLEJA IMRT/VMAT": { "list": ["P002519", "P002800"] },
    "ESTANDAR CONFORMACIONAL": { "list": ["P002499", "P002801"] },
    "ALTAMENTE COMPLEJA /SBRT": { "list": ["P002799"] },
    "CONVENCIONAL": { "list": ["P002492", "P002802"] }
  },
  {
    "DIAGNOSTIC_LIST": "MELANOMA COROIDEO",
    "COMPLEJA IMRT/VMAT": { "list": ["P002526", "P002800"] },
    "ESTANDAR CONFORMACIONAL": { "list": ["P002513", "P002801"] },
    "ALTAMENTE COMPLEJA /SBRT": { "list": ["P002799"] },
    "CONVENCIONAL": { "list": ["P002492", "P002802"] }
  },
  {
    "DIAGNOSTIC_LIST": "MENINGIOMA",
    "COMPLEJA IMRT/VMAT": { "list": ["P002526", "P002800"] },
    "ESTANDAR CONFORMACIONAL": { "list": ["P002513", "P002801"] },
    "ALTAMENTE COMPLEJA /SBRT": { "list": ["P002799"] },
    "CONVENCIONAL": { "list": ["P002492", "P002802"] }
  },
  {
    "DIAGNOSTIC_LIST": "MESOTELIOMA",
    "COMPLEJA IMRT/VMAT": { "list": ["P002520", "P002800"] },
    "ESTANDAR CONFORMACIONAL": { "list": ["P002507", "P002800"] },
    "ALTAMENTE COMPLEJA /SBRT": { "list": ["P002799"] },
    "CONVENCIONAL": { "list": ["P002492", "P002802"] }
  },
  {
    "DIAGNOSTIC_LIST": "MIELOMA",
    "COMPLEJA IMRT/VMAT": { "list": ["P002524", "P002800"] },
    "ESTANDAR CONFORMACIONAL": { "list": ["P002511", "P002801"] },
    "ALTAMENTE COMPLEJA /SBRT": { "list": ["P002799"] },
    "CONVENCIONAL": { "list": ["P002492", "P002802"] }
  },
  {
    "DIAGNOSTIC_LIST": "NEUROBLASTOMA",
    "COMPLEJA IMRT/VMAT": { "list": ["P002518", "P002800"] },
    "ESTANDAR CONFORMACIONAL": { "list": ["P002498", "P002801"] },
    "ALTAMENTE COMPLEJA /SBRT": { "list": ["P002799"] },
    "CONVENCIONAL": { "list": ["P002492", "P002802"] }
  },
  {
    "DIAGNOSTIC_LIST": "ORBITOPATIA",
    "COMPLEJA IMRT/VMAT": { "list": ["P002526", "P002800"] },
    "ESTANDAR CONFORMACIONAL": { "list": ["P002513", "P002801"] },
    "ALTAMENTE COMPLEJA /SBRT": { "list": ["P002799"] },
    "CONVENCIONAL": { "list": ["P002492", "P002802"] }
  },
  {
    "DIAGNOSTIC_LIST": "OVARIO",
    "COMPLEJA IMRT/VMAT": { "list": ["P002517", "P002800"] },
    "ESTANDAR CONFORMACIONAL": { "list": ["P002497", "P002801"] },
    "ALTAMENTE COMPLEJA /SBRT": { "list": ["P002799"] },
    "CONVENCIONAL": { "list": ["P002492", "P002802"] }
  },
  {
    "DIAGNOSTIC_LIST": "PALIATIVO (hasta 2 areas)",
    "COMPLEJA IMRT/VMAT": { "list": ["P002800"] },
    "ESTANDAR CONFORMACIONAL": { "list": ["P002801"] },
    "CONVENCIONAL": { "list": ["P002492", "P002802"] }
  },
  {
    "DIAGNOSTIC_LIST": "PANCREAS",
    "COMPLEJA IMRT/VMAT": { "list": ["P002517", "P002800"] },
    "ESTANDAR CONFORMACIONAL": { "list": ["P002497", "P002801"] },
    "ALTAMENTE COMPLEJA /SBRT": { "list": ["P002799"] },
    "CONVENCIONAL": { "list": ["P002492", "P002802"] }
  },
  {
    "DIAGNOSTIC_LIST": "PENE",
    "COMPLEJA IMRT/VMAT": { "list": ["P002521", "P002800"] },
    "ESTANDAR CONFORMACIONAL": { "list": ["P002508", "P002801"] },
    "ALTAMENTE COMPLEJA /SBRT": { "list": ["P002799"] },
    "CONVENCIONAL": { "list": ["P002492", "P002802"] }
  },
  {
    "DIAGNOSTIC_LIST": "PIEL",
    "COMPLEJA IMRT/VMAT": { "list": ["P002519", "P002800"] },
    "ESTANDAR CONFORMACIONAL": { "list": ["P002499", "P002801"] },
    "ALTAMENTE COMPLEJA /SBRT": { "list": ["P002799"] },
    "CONVENCIONAL": { "list": ["P002492", "P002802"] }
  },
  {
    "DIAGNOSTIC_LIST": "PLASMOCITOMA",
    "COMPLEJA IMRT/VMAT": { "list": ["P002524", "P002800"] },
    "ESTANDAR CONFORMACIONAL": { "list": ["P002511", "P002801"] },
    "ALTAMENTE COMPLEJA /SBRT": { "list": ["P002799"] },
    "CONVENCIONAL": { "list": ["P002492", "P002802"] }
  },
  {
    "DIAGNOSTIC_LIST": "PROSTATA",
    "COMPLEJA IMRT/VMAT": { "list": ["P002517", "P002800"] },
    "ESTANDAR CONFORMACIONAL": { "list": ["P002497", "P002801"] },
    "ALTAMENTE COMPLEJA /SBRT": { "list": ["P002799"] },
    "CONVENCIONAL": { "list": ["P002492", "P002802"] }
  },
  {
    "DIAGNOSTIC_LIST": "PULMON",
    "COMPLEJA IMRT/VMAT": { "list": ["P002520", "P002800"] },
    "ESTANDAR CONFORMACIONAL": { "list": ["P002507", "P002800"] },
    "ALTAMENTE COMPLEJA /SBRT": { "list": ["P002799"] },
    "CONVENCIONAL": { "list": ["P002492", "P002802"] }
  },
  {
    "DIAGNOSTIC_LIST": "QUELOIDE",
    "CONVENCIONAL": { "list": ["P002802"] }
  },
  {
    "DIAGNOSTIC_LIST": "RABDOMIOSARCOMA",
    "COMPLEJA IMRT/VMAT": { "list": ["P002525", "P002800"] },
    "ESTANDAR CONFORMACIONAL": { "list": ["P002512", "P002801"] },
    "ALTAMENTE COMPLEJA /SBRT": { "list": ["P002799"] },
    "CONVENCIONAL": { "list": ["P002492", "P002802"] }
  },
  {
    "DIAGNOSTIC_LIST": "RECTO",
    "COMPLEJA IMRT/VMAT": { "list": ["P002517", "P002800"] },
    "ESTANDAR CONFORMACIONAL": { "list": ["P002497", "P002801"] },
    "ALTAMENTE COMPLEJA /SBRT": { "list": ["P002799"] },
    "CONVENCIONAL": { "list": ["P002492", "P002802"] }
  },
  {
    "DIAGNOSTIC_LIST": "RIÑON/RENAL",
    "COMPLEJA IMRT/VMAT": { "list": ["P002517", "P002800"] },
    "ESTANDAR CONFORMACIONAL": { "list": ["P002497", "P002801"] },
    "ALTAMENTE COMPLEJA /SBRT": { "list": ["P002799"] },
    "CONVENCIONAL": { "list": ["P002492", "P002802"] }
  },
  {
    "DIAGNOSTIC_LIST": "SARCOMA",
    "COMPLEJA IMRT/VMAT": { "list": ["P002525", "P002800"] },
    "ESTANDAR CONFORMACIONAL": { "list": ["P002512", "P002801"] },
    "ALTAMENTE COMPLEJA /SBRT": { "list": ["P002799"] },
    "CONVENCIONAL": { "list": ["P002492", "P002802"] }
  },
  {
    "DIAGNOSTIC_LIST": "SARCOMA EWING",
    "COMPLEJA IMRT/VMAT": { "list": ["P002525", "P002800"] },
    "ESTANDAR CONFORMACIONAL": { "list": ["P002512", "P002801"] },
    "ALTAMENTE COMPLEJA /SBRT": { "list": ["P002799"] },
    "CONVENCIONAL": { "list": ["P002492", "P002802"] }
  },
  {
    "DIAGNOSTIC_LIST": "SCHWANNOMA",
    "COMPLEJA IMRT/VMAT": { "list": ["P002526", "P002800"] },
    "ESTANDAR CONFORMACIONAL": { "list": ["P002513", "P002801"] },
    "ALTAMENTE COMPLEJA /SBRT": { "list": ["P002799"] },
    "CONVENCIONAL": { "list": ["P002492", "P002802"] }
  },
  {
    "DIAGNOSTIC_LIST": "SINOVITIS VILLONODULAR",
    "COMPLEJA IMRT/VMAT": { "list": ["P002525", "P002800"] },
    "ESTANDAR CONFORMACIONAL": { "list": ["P002512", "P002801"] },
    "ALTAMENTE COMPLEJA /SBRT": { "list": ["P002799"] },
    "CONVENCIONAL": { "list": ["P002492", "P002802"] }
  },
  {
    "DIAGNOSTIC_LIST": "SVCS",
    "COMPLEJA IMRT/VMAT": { "list": ["P002800"] },
    "ESTANDAR CONFORMACIONAL": { "list": ["P002801"] },
    "CONVENCIONAL": { "list": ["P002492", "P002802"] }
  },
  {
    "DIAGNOSTIC_LIST": "TESTICULO",
    "COMPLEJA IMRT/VMAT": { "list": ["P002521", "P002800"] },
    "ESTANDAR CONFORMACIONAL": { "list": ["P002508", "P002801"] },
    "ALTAMENTE COMPLEJA /SBRT": { "list": ["P002799"] },
    "CONVENCIONAL": { "list": ["P002492", "P002802"] }
  },
  {
    "DIAGNOSTIC_LIST": "TIMO",
    "COMPLEJA IMRT/VMAT": { "list": ["P002520", "P002800"] },
    "ESTANDAR CONFORMACIONAL": { "list": ["P002507", "P002800"] },
    "ALTAMENTE COMPLEJA /SBRT": { "list": ["P002799"] },
    "CONVENCIONAL": { "list": ["P002492", "P002802"] }
  },
  {
    "DIAGNOSTIC_LIST": "TIROIDES",
    "COMPLEJA IMRT/VMAT": { "list": ["P002518", "P002800"] },
    "ESTANDAR CONFORMACIONAL": { "list": ["P002498", "P002801"] },
    "ALTAMENTE COMPLEJA /SBRT": { "list": ["P002799"] },
    "CONVENCIONAL": { "list": ["P002492", "P002802"] }
  },
  {
    "DIAGNOSTIC_LIST": "TUMOR DE WILMS",
    "COMPLEJA IMRT/VMAT": { "list": ["P002525", "P002800"] },
    "ESTANDAR CONFORMACIONAL": { "list": ["P002512", "P002801"] },
    "ALTAMENTE COMPLEJA /SBRT": { "list": ["P002799"] },
    "CONVENCIONAL": { "list": ["P002492", "P002802"] }
  },
  {
    "DIAGNOSTIC_LIST": "TUMOR DESMOIDES",
    "COMPLEJA IMRT/VMAT": { "list": ["P002525", "P002800"] },
    "ESTANDAR CONFORMACIONAL": { "list": ["P002512", "P002801"] },
    "ALTAMENTE COMPLEJA /SBRT": { "list": ["P002799"] },
    "CONVENCIONAL": { "list": ["P002492", "P002802"] }
  },
  {
    "DIAGNOSTIC_LIST": "VAGINA",
    "COMPLEJA IMRT/VMAT": { "list": ["P002522", "P002800"] },
    "ESTANDAR CONFORMACIONAL": { "list": ["P002509", "P002801"] },
    "ALTAMENTE COMPLEJA /SBRT": { "list": ["P002799"] },
    "CONVENCIONAL": { "list": ["P002492", "P002802"] }
  },
  {
    "DIAGNOSTIC_LIST": "VEJIGA",
    "COMPLEJA IMRT/VMAT": { "list": ["P002517", "P002800"] },
    "ESTANDAR CONFORMACIONAL": { "list": ["P002497", "P002801"] },
    "ALTAMENTE COMPLEJA /SBRT": { "list": ["P002799"] },
    "CONVENCIONAL": { "list": ["P002492", "P002802"] }
  },
  {
    "DIAGNOSTIC_LIST": "VESICULA",
    "COMPLEJA IMRT/VMAT": { "list": ["P002517", "P002800"] },
    "ESTANDAR CONFORMACIONAL": { "list": ["P002497", "P002801"] },
    "ALTAMENTE COMPLEJA /SBRT": { "list": ["P002799"] },
    "CONVENCIONAL": { "list": ["P002492", "P002802"] }
  },
  {
    "DIAGNOSTIC_LIST": "VIA BILIAR",
    "COMPLEJA IMRT/VMAT": { "list": ["P002517", "P002800"] },
    "ESTANDAR CONFORMACIONAL": { "list": ["P002497", "P002801"] },
    "ALTAMENTE COMPLEJA /SBRT": { "list": ["P002799"] },
    "CONVENCIONAL": { "list": ["P002492", "P002802"] }
  },
  {
    "DIAGNOSTIC_LIST": "VULVA",
    "COMPLEJA IMRT/VMAT": { "list": ["P002522", "P002800"] },
    "ESTANDAR CONFORMACIONAL": { "list": ["P002509", "P002801"] },
    "ALTAMENTE COMPLEJA /SBRT": { "list": ["P002799"] },
    "CONVENCIONAL": { "list": ["P002492", "P002802"] }
  },
  {
    "DIAGNOSTIC_LIST": "OTRO",
  }
];