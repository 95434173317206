import {SERVICE} from "@shared/constants/derivations.dev.const";
import {EXAM_AREAS, TAGS_PRM} from "@shared/constants/indication.dev.const";
import {INTEGRAL_HEALTH_PLANS} from "@shared/constants/patient.dev.const";
import {BOX_CENTERS, TAG, TAG_SAN_JOAQUIN} from "@shared/constants/schedule.dev.const";

export const environment = {
  production: false,
  isProduction: false,
  stage: 'dev',
  version: 'Entorno de Desarrollo | v3.70.0',
  zoomApiKey: 'lnT-gzgnS5-KhHLz_duBTg',
  defaultBucketS3: 'portalclinico-dev-pri-usw2',
  supportEmail: 'soporterme@ucchristus.cl',
  teamImagesEmails: ['dmartinezbello@gmail.com'],
  domain: '.ucchristus.io',
  appDomain: 'portalclinico.dev',

  worldTimeApi: 'https://worldtimeapi.org',
  baseApiPCv2: 'https://api.dev.ucchristus.io',
  serverAccessLinks: 'http://192.168.150.229:6001/RedirectServices',
  baseApiSchedule: 'https://apigw.ucchristus.cl/agendaambulatoria-test',
  baseApiRME: 'https://th9n7vjum0.execute-api.us-east-1.amazonaws.com',
  baseApiFC2: 'https://yn954rqyk2.execute-api.us-east-1.amazonaws.com',
  baseApiGES: 'http://146.155.200.74:7777',
  baseApiProfessionalImage: 'https://assets.ucchristus.cl/fotosProfesionales/test',
  nextControlBaseSchedule: 'http://no-disponible-dev/agenda',

  baseApiExamRecords: ' https://q99qh0i7x7.execute-api.us-east-1.amazonaws.com',
  baseApiIntegralHealth: 'https://ecommerceucv2.lfi.cl',
  baseApiAmbulatory: 'https://apigw.ucchristus.cl/agendaambulatoria-test', //cambiar a pre para probar
  baseApiAgendaUc: 'https://agendauctest.lfi.cl',

  baseTableau: 'https://bi.ucchristus.cl/t/bi-portal/views',
  tableauCookie: 'https://5zeywqajfc.execute-api.us-east-1.amazonaws.com/prod/tableau',
  chatInnovit: 'https://storage.googleapis.com/webchats/production/ucch/bundle.js',
  baseSurgery: 'https://bumn1o7am0.execute-api.us-east-1.amazonaws.com',

  linkTyC: 'https://rme-prd-terms-conditions.s3.amazonaws.com/Terminos_Condicione_UC_CHRISTUS_(Venta_medicamentos).pdf',
  linkPP: 'https://rme-prd-terms-conditions.s3.amazonaws.com/Poli%CC%81tica+de+Privacidad+RME+V31.07.2020.pdf',

  derivationService: SERVICE,
  tagsPRMIndications: TAGS_PRM,
  boxCentersSchedule: BOX_CENTERS,
  examAreasIndications: EXAM_AREAS,
  specialtiesGeneralTagsSchedule: TAG,
  integralHealthPlansPatient: INTEGRAL_HEALTH_PLANS,
  specialtiesSanJoaquinTagsSchedule: TAG_SAN_JOAQUIN,

};
